<template>
  <div class="skills-hobbies">
    <div class="g-container">
      <h1>Soft Skills &amp; Hobbies</h1>
      <div class="soft-skills">
        <h2>Soft Skills</h2>
        <ul>
          <li>I always learn and research new technologies</li>
          <li>Time Management</li>
          <li>Ability to Work Under Pressure</li>
          <li>Self-learning</li>
          <li>Good Communication Skills</li>
          <li>Creativity</li>
          <li>Teamwork</li>
        </ul>
      </div>
      <div class="hobbies">
        <h2>Hobbies</h2>
        <ul>
          <li>Travel</li>
          <li>Hiking (Camping)</li>
          <li>play sport</li>
          <li>Skydiving</li>
          <li>Read</li>
          <li>coding</li>
        </ul>
        <div class="skydiving-imgs">
          <div>
            <img
              src="@/assets/img/skydiving1.jpg"
              alt="skydiving"
              draggable="true"
            />
          </div>
          <div>
            <img
              src="@/assets/img/skydiving2.jpg"
              alt="skydiving"
              draggable="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SoftSkillsHobbies",
};
</script>

<style lang="scss" scoped>
.skills-hobbies {
  padding: 50px 5px;
  .g-container {
    h1 {
      text-align: center;
      margin-bottom: 40px;
    }
    .soft-skills {
      padding: 10px;
      h2 {
        margin-bottom: 10px;
      }
      ul {
        li {
          margin: 8px 0 0 20px;
        }
      }
    }
    .hobbies {
      padding: 10px;
      h2 {
        margin-bottom: 10px;
      }
      ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-left: 20px;
        margin-bottom: 30px;
        li {
          background-color: #000;
          color: #fff;
          padding: 5px 10px;
          margin: 5px;
          border-radius: 5px;
        }
      }
      .skydiving-imgs {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        gap: 20px;
        div {
          overflow: hidden;
          border-radius: 10px;
          box-shadow: 0 0 5px -4px #999;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
</style>
